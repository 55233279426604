@import "global.css";

body {
    background: #f4f8fb;
}

.img {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
}

.hidden {
    visibility: hidden;
    position: absolute;
    text-indent: -9999px;
}

.site-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-main {
    z-index: 100;
    flex-grow: 1;
}

.outer {
    position: relative;
    padding: 0 4vw;
}

.inner {
    margin: 0 auto;
    max-width: 1040px;
    width: 100%;
}

@media (min-width: 900px) {
    .home-template .post-feed,
    .tag-template .post-feed,
    .author-template .post-feed {
        margin-top: -70px;
        padding-top: 0;
    }
}

.site-header {
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #fff;
    background: color(var(--darkgrey) l(-5%)) no-repeat center center;
    background-size: cover;
}

.site-header:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
}

.site-header:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 80px;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.site-header.no-cover:before,
.site-header.no-cover:after {
    display: none;
}

.site-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vw 4vw;
    min-height: 200px;
    max-height: 450px;
    text-align: center;
}

.site-title {
    z-index: 10;
    margin: 0;
    padding: 0;
    font-size: 3.8rem;
    font-weight: 700;
}

.site-logo {
    max-height: 45px;
}

.site-description {
    z-index: 10;
    margin: 0;
    padding: 5px 0;
    font-size: 2.2rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    opacity: 0.8;
}

@media (max-width: 500px) {
    .site-title {
        font-size: 3rem;
    }

    .site-description {
        font-size: 1.8rem;
    }
}

.site-nav {
    position: relative;
    z-index: 300;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: hidden;
    height: 40px;
    font-size: 1.2rem;
    text-shadow: 1px 1px 2px black;
}

.site-nav-left {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin-right: 10px;
    padding-bottom: 80px;
    letter-spacing: 0.4px;
    white-space: nowrap;

    -ms-overflow-scrolling: touch;
}

.site-nav-logo {
    flex-shrink: 0;
    display: block;
    margin-right: 24px;
    padding: 11px 0;
    color: #fff;
    font-size: 1.7rem;
    line-height: 1em;
    font-weight: bold;
    letter-spacing: -0.5px;
}

.site-nav-logo:hover {
    text-decoration: none;
}

.site-nav-logo img {
    display: block;
    width: auto;
    height: 21px;
}

.nav ul {
    display: flex;
    margin: 0 0 0 -12px;
    padding: 0;
    list-style: none;
}

.nav li {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

.nav li a {
    display: block;
    margin: 0;
    padding: 10px 12px;
    opacity: 0.8;
}

.nav li a:hover {
    text-decoration: none;
    opacity: 1;
}

.nav-mini {
    display: none;
    float:right;
}

@media (max-width: 450px) {
    .nav {
        display: none;
    }
    .nav-mini {
        display: initial;
    }
    
    .floating-header-title, .site-nav-right {
        padding-right: 20px;
    }
}

.site-nav-right {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 40px;
}

.social-links {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.social-links a:last-of-type {
    padding-right: 20px;
}

.social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10px;
    color: #fff;
    opacity: 0.8;
}

.social-link:hover {
    opacity: 1;
}

.social-link svg {
    height: 1.8rem;
    fill: #fff;
}

.social-link-fb svg {
    height: 1.5rem;
}

.social-link-wb svg {
    height: 1.6rem;
}

.social-link-wb svg path {
    stroke: #fff;
}

.social-link-rss svg {
    height: 1.9rem;
}

.subscribe-button {
    display: block;
    padding: 4px 10px;
    border: #fff 1px solid;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1em;
    border-radius: 10px;
    opacity: 0.8;
}

.subscribe-button:hover {
    text-decoration: none;
    opacity: 1;
}

.rss-button {
    opacity: 0.8;
}

.rss-button:hover {
    opacity: 1;
}

.rss-button svg {
    margin-bottom: 1px;
    height: 2.1rem;
    fill: #fff;
}

@media (max-width: 700px) {
    .site-header {
        padding-right: 0;
        padding-left: 0;
    }

    .site-nav-left {
        margin-right: 0;
        padding-left: 4vw;
    }

    /*.site-nav-right {*/
        /*display: none;*/
    /*}*/
}

.post-feed {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding: 40px 0 0 0;
}

.post-card {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 20px 40px;
    min-height: 300px;
    background: #fff center center;
    background-size: cover;
    border-radius: 5px;
    box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
    transition: all 0.5s ease;
}

.post-card:hover {
    box-shadow: 0 0 1px rgba(39, 44, 49, 0.10), 0 3px 16px rgba(39, 44, 49, 0.07);
    transition: all 0.3s ease;
    transform: translate3D(0, -1px, 0);
}

.post-card-image-link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}

.post-card-image {
    width: auto;
    height: 200px;
    background: var(--lightgrey) no-repeat center center;
    background-size: cover;
}

.post-card-content-link {
    position: relative;
    display: block;
    padding: 25px 25px 0;
    color: var(--darkgrey);
}

.post-card-content-link:hover {
    text-decoration: none;
}

.post-card-tags {
    display: inline;
    margin-bottom: 4px;
    margin-right: 7px;
    color: var(--midgrey);
    font-size: 1.2rem;
    line-height: 1.15em;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.post-card-title {
    margin-top: 0;
}

.post-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.post-card-excerpt {
    font-family: Georgia, serif;
}

.post-card-meta {
    padding: 0 25px 25px;
}

.author-profile-image {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 100%;

    object-fit: cover;
}

.post-card-author {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

@media (max-width: 650px) {
    .post-feed {
        padding-top: 5vw;
    }

    .post-card {
        margin: 0 20px 5vw;
    }
}

.post-template .site-main,
.page-template .site-main {
    padding-bottom: 4vw;
    background: #fff;
}

.post-full {
    position: relative;
    z-index: 50;
}

.post-full-header {
    padding: 2vw 3vw 3vw;
    width: 100%;
    text-align: center;

    margin: 0 -0vw -165px;
    height: 800px;
    background: center center;
    background-size: cover;
    border-radius: 5px;
}

@media (max-width: 500px) {
    .post-full-header {
        padding: 14vw 3vw 10vw;
        width: 100%;
        max-height: 400px;
    }
}

.post-full-meta {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--midgrey);
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
}

.post-full-meta-date {
    color: var(--blue);
}

.post-full-title {
    margin: 0;
    color: color(var(--darkgrey) l(-5%));
}

.date-divider {
    display: inline-block;
    margin: 0 6px 1px;
}

.post-full-image {
    margin: 0 -0vw -165px;
    height: 800px;
    background: var(--lightgrey) center center;
    background-size: cover;
    border-radius: 5px;
}

@media (max-width: 800px) {
    .post-full-image {
        height: 400px;
    }

    .post-full-header {
        height: 400px;
    }
}

.post-full-content {
    position: relative;
    margin: 0 auto;
    min-height: 230px;
    font-family: Georgia, serif;
    font-size: 2.2rem;
    line-height: 1.6em;
    display: table;
    padding: 0;
    background-color: transparent;
}

.side-navigation {
    display: table-cell;
    width: 200px;
}

.post-navigation.fixed {
    position: fixed;
    top: 63px
}

.side-navigation-outer {
    vertical-align: top;
    display: table-cell;
    width: 300px;
}

@media (max-width: 1170px) and (min-width: 501px) {
    .kg-card-markdown {
        width: 80vw;
    }

    .post-full-meta {
        margin-top: 83px;
        font-size: 115%;
    }
}

@media (min-width: 500px) and (max-width: 1170px) {
    .kg-card-markdown p:first-of-type {
        margin-top: 7vw;
    }
}

@media (max-width: 1170px) {
    .side-navigation-outer {
        display: none;
    }

    .kg-card-markdown {
        display: block;
        width: 80vw;

    }

    .post-full-content {
        padding: 0 7vw 0;
        background-color: white;
    }

    .post-full-image {
        margin: 0 -4vw -100px;
        height: 600px;
        border-radius: 0;
    }

    .post-full-header {
        height: 600px;
        border-radius: 0;
    }
}

@media (max-width: 800px) {
    .post-full-content {
        font-size: 1.9rem;
    }
}

.white-box {
    display: block;
    position: relative;
    width: 100px;
    height: 220px;
    background-color: #fff;
    vertical-align: top;
}

.white-box-left {
    float: right;
}

.white-box-right {
    float: none;
}

@media (min-width: 1170px) {
    .white-box-left:before {
        content: "";
        position: absolute;
        top: 15px;
        left: -6px;
        z-index: -1;
        display: block;
        width: 20px;
        height: 200px;
        background: rgba(39, 44, 49, 0.15);
        filter: blur(5px);
        transform: rotate(-5deg);
    }

    .white-box-right:after {
        content: "";
        position: absolute;
        top: 15px;
        right: -6px;
        z-index: -1;
        display: block;
        width: 20px;
        height: 200px;
        background: rgba(39, 44, 49, 0.15);
        filter: blur(5px);
        transform: rotate(5deg);
    }
}

@media (max-width: 1170px) {
    .post-full-content:before {
        content: "";
        position: absolute;
        top: 15px;
        left: -5px;
        z-index: -1;
        display: block;
        width: 20px;
        height: 200px;
        background: rgba(39, 44, 49, 0.15);
        filter: blur(5px);
        transform: rotate(-5deg);
    }

    .post-full-content:after {
        content: "";
        position: absolute;
        top: 15px;
        right: -5px;
        z-index: -1;
        display: block;
        width: 20px;
        height: 200px;
        background: rgba(39, 44, 49, 0.15);
        filter: blur(5px);
        transform: rotate(5deg);
    }
}

@media (max-width: 1270px) {
    .side-navigation {
        display: none;
    }

    .white-box-left {
        margin-left: 0;
    }

    .white-box-right {
        margin-right: 0;
    }
}

.no-image .post-full-content {
    padding-top: 0;
}

.no-image .post-full-content:before,
.no-image .post-full-content:after {
    display: none;
}

.kg-card-markdown {
    align-items: center;
    max-width: 840px;
    margin: 0 auto;
    background-color: white
}

@media (min-width: 1171px) {
    .kg-card-markdown {
        display: table-cell;
        vertical-align: top;
        padding-top: 70px;
    }

    .post-full-meta {
        margin-top: 200px;
        font-size: 140%;
    }

    .post-full-title {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }
}

.post-full-content h1,
.post-full-content h2,
.post-full-content h3,
.post-full-content h4,
.post-full-content h5,
.post-full-content h6,
.post-full-content p,
.post-full-content ul,
.post-full-content ol,
.post-full-content dl,
.post-full-content pre,
.post-full-content blockquote,
.post-full-comments,
.footnotes {
    min-width: 100%;
}

.post-full-content li {
    word-break: break-word;
}

.post-full-content li p {
    margin: 0;
}

.post-template .kg-card-markdown > p:first-child {
    font-size: 1.25em;
    line-height: 1.5em;
}

.post-full-content a {
    color: #000;
    box-shadow: var(--blue) 0 -1px 0 inset;
}

.post-full-content a:hover {
    color: var(--blue);
    text-decoration: none;
}

.post-full-content strong,
.post-full-content em {
    color: color(var(--darkgrey) l(-5%));
}

.post-full-content small {
    display: inline-block;
    line-height: 1.6em;
}

.post-full-content li:first-child {
    margin-top: 0;
}

.post-full-content img,
.post-full-content video {
    display: block;
    margin: 1.5em auto;
    max-width: 840px;
}

@media (max-width: 840px) {
    .post-full-content img,
    .post-full-content video {
        width: 100%;
    }
}

.post-full-content img[src$="#full"] {
    max-width: none;
    width: 100vw;
}

.post-full-content img + br + small {
    display: block;
    margin-top: -3em;
    margin-bottom: 1.5em;
}


.post-full-content iframe {
    margin: 0 auto;
}

.post-full-content blockquote {
    margin: 0 0 1.5em;
    padding: 0 1.5em;
    border-left: #3eb0ef 3px solid;
}

.post-full-content blockquote p {
    margin: 0 0 1em 0;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: italic;
}

.post-full-content blockquote p:last-child {
    margin-bottom: 0;
}

.post-full-content code {
    padding: 0 5px 2px;
    font-size: 0.8em;
    line-height: 1em;
    font-weight: 400 !important;
    background: var(--whitegrey);
    border-radius: 3px;
}

.post-full-content pre {
    overflow-x: auto;
    margin: 1.5em 0 3em;
    padding: 20px;
    max-width: 100%;
    border: color(var(--darkgrey) l(-10%)) 1px solid;
    color: var(--whitegrey);
    font-size: 1.4rem;
    line-height: 1.5em;
    background: color(var(--darkgrey) l(-3%));
    border-radius: 5px;
}

.post-full-content pre code {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    background: transparent;
}

.post-full-content pre code * {
    color: inherit;
}

.post-full-content .fluid-width-video-wrapper {
    margin: 1.5em 0 3em;
}

.post-full-content hr {
    margin: 4vw 0;
}

.post-full-content hr:after {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    display: block;
    margin-left: -10px;
    width: 1px;
    height: 30px;
    background: color(var(--lightgrey) l(+10%));
    box-shadow: #fff 0 0 0 5px;
    transform: rotate(45deg);
}

.post-full-content h1,
.post-full-content h2,
.post-full-content h3,
.post-full-content h4,
.post-full-content h5,
.post-full-content h6 {
    color: color(var(--darkgrey) l(-5%));
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.post-full-content h1 {
    margin: 0.5em 0 0.2em 0;
    font-size: 4.6rem;
    font-weight: 700;
}

@media (max-width: 500px) {
    .post-full-content h1 {
        font-size: 2.8rem;
    }

    .kg-card-markdown {
        padding-top: 3.8vw;
    }
}

.post-full-content h2 {
    margin: 0.5em 0 0.2em 0;
    font-size: 3.6rem;
    font-weight: 700;
}

@media (max-width: 500px) {
    .post-full-content h2 {
        font-size: 2.6rem;
    }
}

.post-full-content h3 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.8rem;
    font-weight: 700;
}

@media (max-width: 500px) {
    .post-full-content h3 {
        font-size: 2.2rem;
    }
}

.post-full-content h4 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.8rem;
    font-weight: 700;
}

@media (max-width: 500px) {
    .post-full-content h4 {
        font-size: 2.2rem;
    }
}

.post-full-content h5 {
    display: block;
    margin: 0.5em 0;
    padding: 1em 0 1.5em;
    border: 0;
    color: var(--blue);
    font-family: Georgia, serif;
    font-size: 3.2rem;
    line-height: 1.35em;
    text-align: center;
}

@media (min-width: 1180px) {
    .post-full-content h5 {
        max-width: 1060px;
        width: 100vw;
    }
}

@media (max-width: 500px) {
    .post-full-content h5 {
        padding: 0 0 0.5em;
        font-size: 2.2rem;
    }
}

.post-full-content h6 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.3rem;
    font-weight: 700;
}

@media (max-width: 500px) {
    .post-full-content h6 {
        font-size: 2rem;
    }
}

.footnotes-sep {
    margin-bottom: 30px;
}

.footnotes {
    font-size: 1.5rem;
}

.footnotes p {
    margin: 0;
}

.footnote-backref {
    color: var(--blue) !important;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none !important;
    box-shadow: none !important;
}

@media (max-width: 500px) {
    .post-full-meta {
        font-size: 1.5rem;
        margin-bottom: 7px;
        line-height: 1.3em;
    }

    .post-full-title {
        font-size: 2.9rem;
    }

    .post-full-image {
    }

    .post-full-content {
        padding: 0;
        display: block;
    }

    .post-full-content:before,
    .post-full-content:after {
        display: none;
    }

    .kg-card-markdown {
        width: 94%;
    }
}

.post-full-content table {
    display: inline-block;
    overflow-x: auto;
    margin: 0.5em 0 2.5em;
    max-width: 100%;
    width: auto;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
    white-space: nowrap;
    vertical-align: top;
}

.post-full-content table {
    -webkit-overflow-scrolling: touch;
    background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center, radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
    background-attachment: scroll, scroll;
    background-size: 10px 100%, 10px 100%;
    background-repeat: no-repeat;
}

.post-full-content table td:first-child {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    background-size: 20px 100%;
    background-repeat: no-repeat;
}

.post-full-content table td:last-child {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    background-position: 100% 0;
    background-size: 20px 100%;
    background-repeat: no-repeat;
}

.post-full-content table th {
    color: var(--darkgrey);
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    background-color: color(var(--whitegrey) l(+4%));
}

.post-full-content table th,
.post-full-content table td {
    padding: 6px 12px;
    border: color(var(--whitegrey) l(-1%) s(-5%)) 1px solid;
}

.subscribe-form {
    margin: 1.5em 0;
    margin-bottom: 2.7vw;
    padding: 6.5vw 7vw 7vw;
    border: color(var(--whitegrey) l(+2%)) 1px solid;
    text-align: center;
    background: color(var(--whitegrey) l(+4%));
    border-radius: 7px;
}

.subscribe-form-title {
    margin: 0 0 3px 0;
    padding: 0;
    color: var(--darkgrey);
    font-size: 3.5rem;
    line-height: 1;
    font-weight: 700;
}

.subscribe-form p {
    margin-bottom: 1em;
    color: var(--midgrey);
    font-size: 2.2rem;
    line-height: 1.55em;
    letter-spacing: 0.2px;
}

.subscribe-form form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 420px;
}

.subscribe-form .form-group {
    flex-grow: 1;
}

.subscribe-email {
    display: block;
    padding: 10px;
    width: 100%;
    border: color(var(--lightgrey) l(+7%)) 1px solid;
    color: var(--midgrey);
    font-size: 1.8rem;
    line-height: 1em;
    font-weight: normal;
    user-select: text;
    border-radius: 5px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

.subscribe-email:focus {
    outline: 0;
    border-color: color(var(--lightgrey) l(-2%));
}

.subscribe-form button {
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 0 20px;
    height: 41px;
    outline: none;
    color: #fff;
    font-size: 1.5rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    background: linear-gradient(
            color(var(--blue) whiteness(+7%)),
            color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
            color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
            color(var(--blue) lightness(-4%) saturation(-10%))
    );
    border-radius: 5px;
    box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.14);

    -webkit-font-smoothing: subpixel-antialiased;
}

.subscribe-form button:active,
.subscribe-form button:focus {
    background: color(var(--blue) lightness(-9%) saturation(-10%));
}

@media (max-width: 650px) {
    .subscribe-form-title {
        font-size: 2.4rem;
    }

    .subscribe-form p {
        font-size: 1.6rem;
    }
}

@media (max-width: 500px) {
    .subscribe-form form {
        flex-direction: column;
    }

    .subscribe-form .form-group {
        width: 100%;
    }

    .subscribe-form button {
        margin: 10px 0 0 0;
        width: 100%;
    }
}

.post-full-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 3vw 0 2vw;
    /*padding-bottom: 40px;*/
    max-width: 840px;
}

@media (max-width: 890px) {
    .post-full-footer {
        margin-left: 20px;
        margin-right: 20px;
        padding: 3vw 0 1vw;
    }
}

.author-card {
    display: flex;
    align-items: center;
}

.author-card .author-profile-image {
    margin-right: 15px;
    width: 60px;
    height: 60px;
}

.author-card-name {
    margin: 0 0 2px 0;
    padding: 0;
    font-size: 2rem;
}

.author-card-name a {
    color: var(--darkgrey);
    font-weight: 700;
}

.author-card-name a:hover {
    text-decoration: none;
}

.author-card-content p {
    margin: 0;
    color: var(--midgrey);
    line-height: 1.3em;
}

.post-full-footer-right {
    flex-shrink: 0;
    margin-left: 20px;
}

.author-card-button {
    display: block;
    padding: 9px 16px;
    border: color(var(--midgrey) l(+20%)) 1px solid;
    color: var(--midgrey);
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 500;
    border-radius: 20px;
    transition: all ease 0.2s;
}

.author-card-button:hover {
    border-color: var(--blue);
    color: var(--blue);
    text-decoration: none;
}


.post-full-comments {
    margin: 0 auto;
    max-width: 840px;
    padding-left: 10vw;
    padding-right: 10vw;
}

@media (max-width: 1100px) {
    .post-full-comments {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 600px) {
    .post-full-comments {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.read-next-feed {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding: 40px 0 0 0;
}

.read-next-card {
    position: relative;
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 20px 40px;
    padding: 25px;
    color: #fff;
    background: var(--darkgrey) center center;
    background-size: cover;
    border-radius: 5px;
    box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
}

.read-next-card:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: linear-gradient(135deg, rgba(0, 40, 60, 0.8) 0%, rgba(0, 20, 40, 0.7) 100%);
    border-radius: 5px;

    backdrop-filter: blur(2px);
}

.read-next-card-header {
    position: relative;
    z-index: 50;
    padding-top: 20px;
    text-align: center;
}

.read-next-card-header-sitetitle {
    display: block;
    font-size: 1.3rem;
    line-height: 1.3em;
    opacity: 0.8;
}

.read-next-card-header-title {
    margin: 0;
    padding: 0 20px;
    color: #fff;
    font-size: 3rem;
    line-height: 1.2em;
    letter-spacing: 1px;
}

.read-next-card-header-title a {
    color: #fff;
    font-weight: 300;
    text-decoration: none;
}

.read-next-card-header-title a:hover {
    text-decoration: none;
}

.read-next-divider {
    position: relative;
    display: flex;
    justify-content: center;
    height: 80px;
}

.read-next-divider svg {
    width: 40px;
    fill: transparent;
    stroke: #fff;

    stroke-width: 0.5px;
    stroke-opacity: 0.65;
}

.read-next-card-content {
    position: relative;
    z-index: 50;
    flex-grow: 1;
    display: flex;
    font-size: 1.7rem;
}

.read-next-card-content ul {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    list-style: none;
}

.read-next-card-content li {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.25em;
    font-weight: 200;
    letter-spacing: -0.5px;
}

.read-next-card-content li a {
    display: block;
    padding: 20px 0;
    border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
    color: #fff;
    font-weight: 500;
    vertical-align: top;
    transition: opacity 0.3s ease;
}

.read-next-card-content li:first-of-type a {
    padding-top: 10px;
}

.read-next-card-content li a:hover {
    opacity: 1;
}

.read-next-card-footer {
    position: relative;
    margin: 15px 0 3px 0;
    text-align: center;
}

.read-next-card-footer a {
    color: #fff;
}

.table-of-content-outer {
    padding: 0;
    color: #bfbfbf;
    font-size: 14px;
    display: block !important;
    margin-left: 0;
    width: 16.66666667%;
    float: left;
    position: relative;
    min-height: 1px;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "PingFang SC", "Hiragino Sans GB", STHeiti, "Microsoft YaHei", "Microsoft JhengHei", "Source Han Sans SC", "Noto Sans CJK SC", "Source Han Sans CN", "Noto Sans SC", "Source Han Sans TC", "Noto Sans CJK TC", "WenQuanYi Micro Hei", SimSun, sans-serif;
    line-height: 1.7;
}

.table-of-content-inner {
    display: block;
    overflow: auto;
    height: 100%;
    width: 175px;
    font-size: 14px;
}

.table-of-content-h4 {
    padding-top: 25px;
    padding-left: 9px;
    color: gray;
    padding-bottom: 9px;
    line-height: 1.1;
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0;
}

.table-of-content-span {
    color: gray;
    text-decoration: none;
    background-color: transparent;
    line-height: 1.1;
    font-weight: 700;
    font-size: 14px;
}

.table-of-content-ul {
    padding-left: 15px;
    list-style: none;
    color: gray;
    text-decoration: none;
    background-color: transparent;
    line-height: 1.1;
    font-weight: 700;
    font-size: 14px;
}

.table-of-content-ul li{
    padding: 0 0 0 5px
}

.table-of-content-ul li a{
    margin: 0; 
    display: block; 
    width: 100%; 
    height: 100%
}

.post-navigation {
    visibility: visible;
    align-items: center;
    height: 10px;
    width: 175px;
    /*border-left: rgba(0,0,0,0.06) 1px solid;*/
    /*border-top: rgba(0,0,0,0.06) 1px solid;*/
    /*border-bottom: rgba(0,0,0,0.06) 1px solid;*/
    /*border-right: rgba(0,0,0,0.06) 1px solid;*/
    /*transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);*/
    /*transform: translate3d(0, -120%, 0);*/
    /*background: rgba(255,255,255,0.95);*/
    background-color: transparent;
}

.post-navigation li {
    cursor: pointer;
}

.site-nav li a {
    color: #fff;
}

.nav_active,
.nav_active span,
.nav_active em,
.nav_active i,
.nav_active a,
.nav_active b {
    border-radius: 4px;
    background-color: #F5F5F5;
    color: #0085a1 !important
}

.post-navigation .h2_nav:hover b,
.post-navigation .h2_nav:hover em,
.post-navigation .h2_nav:hover i,
.post-navigation .h2_nav:hover span,
.post-navigation .h2_nav:hover a,
.post-navigation .h3_nav:hover b,
.post-navigation .h3_nav:hover em,
.post-navigation .h3_nav:hover i,
.post-navigation .h3_nav:hover a,
.post-navigation .h3_nav:hover span {
    color: #0085a1;
}

.post-navigation a,
.post-navigation b,
.post-navigation em,
.post-navigation i {
    font-size: 14px;
    color: #636161;
    text-decoration:none;
    box-shadow: unset;
}

.h1_nav,
.h2_nav {
    margin-left: 0;
    font-size: 13px;
    font-weight: 700;
}

.h3_nav {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 700;
}

.floating-header {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
    background: rgba(255, 255, 255, 0.95);
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate3d(0, -120%, 0);
}

.regular-header {
    visibility: visible;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 60px;
    background: rgba(255, 255, 255, 0.95);
}

.floating-header-title li a {
    color: black;
    font-size: 85%
}

.floating-active {
    visibility: visible;
    transition: all 500ms cubic-bezier(0.22, 1, 0.27, 1);
    transform: translate3d(0, 0, 0);
}

.floating-header-logo {
    overflow: hidden;
    margin: 0 0 0 20px;
    font-size: 1.6rem;
    line-height: 1em;
    letter-spacing: -1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 37px
}

.floating-header-logo span {
    margin-top: 3px
}

.floating-header-logo a {
    display: flex;
    align-items: center;
    color: var(--darkgrey);
    line-height: 1.1em;
    font-weight: 700;
}

.floating-header-logo a:hover {
    text-decoration: none;
}

.floating-header-logo img {
    margin: 0 10px 2px 0;
    max-height: 20px;
}

.floating-header-title {
    flex: 1;
    overflow: hidden;
    margin: 3px 0 0 0;
    color: #2e2e2e;
    font-size: 1.6rem;
    line-height: 1.3em;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.floating-header-share {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 2%;
    font-size: 1.3rem;
    line-height: 1;
}

.floating-header-share a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.floating-header-share svg {
    width: auto;
    height: 16px;
    fill: #fff;
}

.floating-header-share-label {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
}

.floating-header-share-label svg {
    margin: 0 5px 0 10px;
    width: 18px;
    height: 18px;
    stroke: rgba(0, 0, 0, 0.7);
    transform: rotate(90deg);
}

.floating-header-share-tw,
.floating-header-share-fb {
    display: block;
    align-items: center;
    width: 60px;
    height: 60px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.floating-header-share-tw {
    background: #33b1ff;
}

.floating-header-share-fb {
    background: #005e99;
}

.progress {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    border: none;
    color: var(--blue);
    background: transparent;

    appearance: none;
}

.progress::-webkit-progress-bar {
    background-color: transparent;
}

.progress::-webkit-progress-value {
    background-color: var(--blue);
}

.progress::-moz-progress-bar {
    background-color: var(--blue);
}

.progress-container {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: transparent;
}

.progress-bar {
    display: block;
    width: 50%;
    height: inherit;
    background-color: var(--blue);
}

@media (max-width: 900px) {
    .floating-header {
        height: 40px;
    }

    .floating-header-title,
    .floating-header-logo {
        font-size: 1.5rem;
    }

    .floating-header-share-tw,
    .floating-header-share-fb {
        width: 40px;
        height: 40px;
        line-height: 38px;
    }
}

@media (max-width: 450px) {
    .floating-header-logo {
        margin-left: 10px;
    }

    .floating-header-logo a {
        color: #2e2e2e;
    }

    /*.floating-header-title {*/
        /*visibility: hidden;*/
    /*}*/
}

@media (max-width: 670px) {
    .floating-header-share {
        display: none
    }
}

.site-header-content .author-profile-image {
    z-index: 10;
    flex-shrink: 0;
    margin: 0 0 20px 0;
    width: 100px;
    height: 100px;
    box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 6px;
}

.site-header-content .author-bio {
    z-index: 10;
    flex-shrink: 0;
    margin: 5px 0 10px 0;
    max-width: 600px;
    font-size: 2rem;
    line-height: 1.3em;
    font-weight: 300;
    letter-spacing: 0.5px;
    opacity: 0.8;
}

.site-header-content .author-meta {
    z-index: 10;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px 0;
    font-family: Georgia, serif;
    font-style: italic;
}

.site-header-content .author-location svg {
    height: 1.9rem;
    stroke: #fff;
}

.site-header-content .bull {
    display: inline-block;
    margin: 0 12px;
    opacity: 0.5;
}

.site-header-content .social-link:first-of-type {
    padding-left: 4px;
}

@media (max-width: 500px) {
    .site-header-content .author-bio {
        font-size: 1.8rem;
        line-height: 1.15em;
        letter-spacing: 0;
    }
}


.error-template .site-main {
    padding: 7vw 4vw;
}

.site-nav-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.site-nav-center .site-nav-logo {
    margin-right: 0;
}

.error-message {
    text-align: center;
}

.error-code {
    margin: 0;
    font-size: 12vw;
    line-height: 1em;
    letter-spacing: -5px;
    opacity: 0.3;
}

.error-description {
    margin: 0;
    color: var(--midgrey);
    font-size: 3rem;
    line-height: 1.3em;
    font-weight: 400;
}

@media (max-width: 800px) {
    .error-description {
        margin: 5px 0 0 0;
        font-size: 1.8rem;
    }
}

.error-link {
    display: inline-block;
    margin-top: 5px;
}

.error-template .post-feed {
    padding-top: 0;
}

.subscribe-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
    justify-content: center;
    align-items: center;
    background: rgba(0, 25, 40, 0.97);
    opacity: 0;
    transition: opacity 200ms ease-in;
    pointer-events: none;

    backdrop-filter: blur(3px);
    display: flex;
    visibility: hidden;
}

.subscribe-overlay-content {
    position: relative;
    z-index: 9999;
    margin: 0 0 5vw 0;
    padding: 4vw;
    color: #fff;
    text-align: center;
}

.subscribe-overlay-logo {
    position: fixed;
    top: 23px;
    left: 30px;
    height: 30px;
}

.subscribe-overlay-title {
    display: inline-block;
    margin: 0 0 10px 0;
    font-size: 6rem;
    line-height: 1.15em;
}

.subscribe-overlay-description {
    margin: 0 auto 50px;
    max-width: 650px;
    font-family: Georgia, serif;
    font-size: 3rem;
    line-height: 1.3em;
    font-weight: 300;
    opacity: 0.8;
}

@media (max-width: 870px) {
    .subscribe-overlay-title {
        margin: 0 0 10px 0;
        font-size: 3rem;
        line-height: 1.15em;
    }

    .subscribe-overlay-description {
        margin: 0 auto 20px;
        max-width: 650px;
        font-size: 2rem;
        line-height: 1.3em;
        font-weight: 300;
    }
}

.subscribe-overlay form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 500px;
}

.subscribe-overlay .form-group {
    flex-grow: 1;
}

.subscribe-overlay .subscribe-email {
    display: block;
    padding: 14px 20px;
    width: 100%;
    border: none;
    color: var(--midgrey);
    font-size: 2rem;
    line-height: 1em;
    font-weight: normal;
    letter-spacing: 0.5px;
    user-select: text;
    border-radius: 8px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

.subscribe-email:focus {
    outline: 0;
    border-color: color(var(--lightgrey) l(-2%));
}

.subscribe-overlay button {
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 0 25px;
    height: 52px;
    outline: none;
    color: #fff;
    font-size: 1.7rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    background: linear-gradient(
            color(var(--blue) whiteness(+7%)),
            color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
            color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
            color(var(--blue) lightness(-4%) saturation(-10%))
    );
    border-radius: 8px;
    box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.14);

    -webkit-font-smoothing: subpixel-antialiased;
}

.subscribe-overlay button:active,
.subscribe-overlay button:focus {
    background: color(var(--blue) lightness(-9%) saturation(-10%));
}

.subscribe-overlay-close-outer {
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    padding-top: 0; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 55px; /* Full height */
    overflow: auto; /* Enable scroll if needed */
}

.subscribe-overlay-close-outer:hover {
    cursor: pointer;
}

.subscribe-overlay-close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    z-index: 9999;
}

.subscribe-overlay-close:before {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(45deg);
}

.subscribe-overlay-close:after {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(-45deg);
}

.subscribe-overlay-close:hover {
    cursor: pointer;
}

.site-footer {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    /*background: color(var(--darkgrey) l(-85%));*/
    background-color: #3c484e;
}

.site-footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.3rem;
}

.site-footer-content a {
    color: rgba(255, 255, 255, 0.7);
}

.site-footer-content a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}

.site-footer-nav {
    display: flex;
}

.site-footer-nav a {
    position: relative;
    margin-left: 20px;
}

.site-footer-nav a:before {
    content: "";
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
}

.site-footer-nav a:first-of-type:before {
    display: none;
}

@media (max-width: 650px) {
    .site-footer-content {
        flex-direction: column;
    }

    .site-footer-nav a:first-child {
        margin-left: 0;
    }
}

.inline-on-mobile {
    display: none;
}

.inline-on-pc {
    display: inline;
}

@media (max-width: 500px) {
    .inline-on-mobile {
        display: inline;
    }

    .inline-on-pc {
        display: none;
    }
}

.social-link-author {
    color: #fff;
    opacity: 0.8;
    line-height: 1.98;
}

.social-link-author:hover {
    opacity: 1;
}

.social-link-author svg {
    height: 1.8rem;
    fill: #fff;
    margin-left: 10px;
    margin-bottom: 4px;
}

.tag {
    color: #000;
    background-color: #f1f1f1 !important;
    display: inline-block;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 8px;
    text-align: center;
    box-sizing: inherit;
    font-family: Verdana, sans-serif;
    font-size: 15px;
    line-height: 1.5;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #0182bd;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.post-card-tags-link {
    color: inherit;
    cursor: pointer;
}

.after-post-tags {
    width: 100%;
    display: inline-block;
}

.nav-container {
    display: inline-block;
    cursor: pointer;
}

.bar1, .bar2, .bar3 {
    width: 32px;
    height: 4px;
    margin: 6px 0;
    transition: 0.4s;
}

.floating-header-title .bar1,
.floating-header-title .bar2,
.floating-header-title .bar3 {
    background-color: #333;
}

.site-nav .bar1,
.site-nav .bar2,
.site-nav .bar3 {
    background-color: white;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.nav-mini-list {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1001;
    top: 50px;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
}

.nav-mini-hidden {
    width: 0;
}

.nav-mini-list ul {
    list-style: none;
}

.nav-mini-list a {
    padding: 8px 8px 8px 8px;
    text-decoration: none;
    font-size: 25px;
    color: #5a5a5a;
    text-shadow: none;
    display: block;
    transition: 0.3s;
}

.nav-mini-list a:hover {
    color: #2f2f2f;
}

@media (max-height: 450px) {
    .nav-mini-list {padding-top: 15px;}
    .nav-mini-list a {
        font-size: 18px;
        padding: 4px 8px 4px 8px;
    }
}

